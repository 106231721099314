const input = document.querySelector('.type-search');
const results = document.querySelector('.results');
const fieldset = document.querySelector('.filter-form').querySelector('fieldset');
const ssrTable = document.querySelector('.ssr-table');
const pagination = document.querySelector('.pagination');
if (!input) return;

const data = window.entries;
if (!data || data.lenth === 0) return;


searchKeys = ['title', 'book_url', 'book_creator_author', 'book_ethnic_tags', 'book_summary'];

input.addEventListener('keyup', function(e) {
  const searchTerm = e.target.value

  fieldset.setAttribute('disabled', 'true');
  ssrTable.style.display = 'none';
  if (pagination) pagination.classList.add('visuallyhidden');

  const filteredData = typeAheadSearch(data, searchKeys, searchTerm);

  while (results.firstChild) {
    results.removeChild(results.firstChild);
  }
  results.appendChild(createTable(filteredData));

  if (searchTerm.length === 0) {
    results.innerHTML = ''
    ssrTable.style.display = 'block';
    if (pagination) pagination.classList.remove('visuallyhidden');
    fieldset.removeAttribute('disabled');
  }
});

function typeAheadSearch(data, keys, query) {
  if (query === '') {
      return data; // return all data if input is empty
  }

  query = query.toLowerCase();

  return data.filter(function(item) {
      // The 'some' function will return true if any of the callback functions return true
      return keys.some(function(key) {
          // If item doesn't have this key or its value is not a string, return false (don't match)
          if (!item.hasOwnProperty(key) || typeof item[key] !== 'string') {
              return false;
          }
          // Check if the string value includes the query
          return item[key].toLowerCase().includes(query);
      });
  });
}

function createTable(data) {
  const table = document.createElement('table');
  table.classList.add('resource-table');
  thead = document.createElement('thead');
  thead.innerHTML = '<tr><th>Title</th><th>Author</th><th>Grade Level</th><th>Topic Tags</th><th>Ethnic Tags</th><th>Region Tags</th><th>Content Area Tags</th><th>Summary</th><th>Link</th></tr>';
  table.appendChild(thead);

  data.forEach(function(item) {

      const row = document.createElement('tr');

      const titleCell = document.createElement('td');
      titleCell.innerHTML = `<a href="${item.url}">${item.title}</a>`;
      row.appendChild(titleCell);

      const authorCell = document.createElement('td');
      authorCell.textContent = item.book_creator_author || ' - ';
      row.appendChild(authorCell);

      const gradeCell = document.createElement('td');
      gradeCell.textContent = item.book_grade_level || ' - ';
      row.appendChild(gradeCell);

      const topicsCell = document.createElement('td');
      topicsCell.textContent = item.book_topic_ta || ' - ';
      row.appendChild(topicsCell);

      const ethnicCell = document.createElement('td');
      ethnicCell.textContent = item.book_ethnic_tags || ' - ';
      row.appendChild(ethnicCell);

      const regionCell = document.createElement('td');
      regionCell.textContent = item.book_region_tags || ' - ';
      row.appendChild(regionCell);

      const contentAreaCell = document.createElement('td');
      contentAreaCell.textContent = item.book_content_area_tags || ' - ';
      row.appendChild(contentAreaCell);

      const summaryCell = document.createElement('td');
      summaryCell.textContent = item.book_summary || ' - ';
      row.appendChild(summaryCell);

      table.appendChild(row);
  });

  return table;
}
