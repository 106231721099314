// up.compiler('.testimonials', function(element) {
  let play = false;

  window.addEventListener('resize', function() {
    blurbs.forEach((slide, indx) => {
      slide.style = `--x: ${indx * 100}%`;
      if (window.innerWidth <= 976)  {
        play = false;
        slide.style = `--x: 0%`;
      } else {
        play = true;
      }
    });
  });

  let mql = window.matchMedia("(max-width: 61rem)");

  const btns = document.querySelectorAll('.slider-control');
  if (btns.length === 0) return;

  const blurbs = document.querySelectorAll('.testimonials figure');
  btns[0].classList.add('active');

  // current slide counter
  let curSlide = 0;
  let autoplayInterval;

  const goToSlide = (index) => {
    btns.forEach((btn) => {
      btn.classList.remove('active');
    });

    blurbs.forEach((slide) => {
      const scrollAmount = 100 * (index * -1);
      slide.style.setProperty('--x', `${scrollAmount}%`);
    });

    curSlide = index;
    btns[index].classList.add('active');
  };

  const startAutoplay = () => {
    if (mql.matches) return

    autoplayInterval = setInterval(() => {
      if (play === false) return;
      const nextSlide = (curSlide + 1) % btns.length;
      goToSlide(nextSlide);
    }, 8000);
  };

  const stopAutoplay = () => {
    clearInterval(autoplayInterval);
  };

  btns.forEach((btn, i) => {
    btn.addEventListener('click', () => {
      stopAutoplay();
      goToSlide(i);
      startAutoplay();
    });
  });

  startAutoplay();

  // Stop autoplay and clean up when needed
  const cleanUpSlider = () => {
    stopAutoplay();
    btns.forEach((btn) => {
      btn.removeEventListener('click');
    });
  };

// })
